import { useRef, useState, useEffect } from 'react';
import { Text, Tooltip } from '@mantine/core';

function TruncatedTextHandler({ text, maxWidth = 200, className, marginTop='0px' }) {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [text]);

  return (
    <Tooltip label={text} style={{marginTop: marginTop}} disabled={!isTruncated}>
      <Text
        ref={textRef}
        style={{
          maxWidth: `${maxWidth}px`,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        className={className}
      >
        {text}
      </Text>
    </Tooltip>
  );
}

export default TruncatedTextHandler;
